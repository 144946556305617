<template>
  <div class="row_contain">
    <van-search
      v-model="itemNum.title"
      show-action
      placeholder="请输入商品编号"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-sticky>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="itemNum.type_id"
          :options="option1"
          @change="listChange"
        />
      </van-dropdown-menu>
    </van-sticky>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="immediate"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-skeleton title :row="6" v-if="loadStart" />
      <div class="cardRow" v-for="(item, index) in list" :key="index" v-else>
        <van-card
          :num="'库存' + item.user_stock"
          :tag="item.goods_code"
          currency="￥"
          :price="item.price"
          :desc="item.des"
          :title="item.title"
          :thumb="item.pic"
        >
          <template #tags>
            <van-tag plain type="danger">{{ item.tag_name }}</van-tag>
          </template>
          <template #price-top>
            <div
              class="reRow"
              :data-id="item.goods_id"
              @click.stop="clickLink"
            ></div>
          </template>
          <template #footer>
            <div class="van-sku-actions">
              <button
                class="van-button van-button--warning van-button--large"
                :data-id="item.goods_id"
                @click.stop="onClickShopping"
              >
                <div class="van-button__content">
                  <span class="van-button__text">加入购物车</span>
                </div>
              </button>
              <button
                class="van-button van-button--danger van-button--large"
                :data-id="item.goods_id"
                @click.stop="onClickButton"
              >
                <div class="van-button__content">
                  <span class="van-button__text">立即购买</span>
                </div>
              </button>
            </div>
          </template>
        </van-card>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  data () {
    return {
      immediate: false,
      loadStart: true,
      value: "",
      value1: 0,
      option1: [],
      list: [],
      loading: false,
      finished: false,
      itemNum: {
        user_id: JSON.parse(localStorage.getItem('userInfo')).user_id || '0',
        page: 1,
        type_id: 0,
        title: ''
      }
    };
  },
  created () {
    this.load();
    this.get_index('加载');
    this.$store.dispatch('CountAsync');
  },
  methods: {
    async get_index (start) {
      let { user_id, page, type_id, title } = this.itemNum;
      let res = await this.$http.get('goods/get_index', {
        params: {
          user_id,
          page,
          type_id,
          title
        }
      })
      if (start == '加载') {
        this.list = res.data.data;
        console.log('as', this.list, res.data.data)
      }
      if (res.data.data.length > 0) {
        if (start == '分页') {
          res.data.data.forEach(item => {
            this.list.push(item)
          });
        }
        this.finished = false;
      } else {

        this.finished = true;
      }
      this.loading = false;
      this.loadStart = false;
    },
    async load () {
      let res = await this.$http.get('goods/get_goods_type');

      if (res) {
        res.data.data.unshift({ text: "全部商品", value: 0 });
        this.option1 = [...res.data.data]
      }

    },
    async onClickShopping (ev) {
      console.log("加入购物车");
      this.$Toast.loading({ forbidClick: true });
      let value = this.$qs.stringify({
        user_id: this.$store.state.login.userInfo.user_id,
        goods_id: ev.target.dataset.id,
        num: 1
      })
      let res = await this.$http.post('cart/add_cart', value);
      if (res && res.data.error_code == 0) {
        this.$store.dispatch('CountAsync');
        this.$Toast.clear();
        this.$Toast('添加购物车成功');
      }
    },

    onClickButton (ev) {
      this.$router.push('/orderList/' + ev.target.dataset.id + '/2')
    },
    clickLink (ev) {
      this.$router.push("/details/" + ev.target.dataset.id);
    },
    async onLoad () {

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // 加载状态结束
      this.loading = true;
      setTimeout(() => {
        this.itemNum.page += 1
        this.get_index('分页')
      }, 1000)
    },
    listChange () {
      this.itemNum.page = 1;

      this.finished = false;
      this.loadStart = true;
      this.get_index('加载')
    },
    onSearch () {

      this.itemNum.page = 1;
      this.finished = false;
      this.loadStart = true;
      this.get_index('加载')

    },
  },
};
</script>

<style lang="less" scoped>
.reRow {
  width: 100%;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}
.van-card__thumb {
  width: 120px;
  height: 120px;
}
.van-card__content {
  padding-right: 10px;
}
.van-card__footer {
  padding: 5px;
  border-top: 1px dashed #c9c9c9;
}
.van-card {
  background: #fff;
  margin-top: 10px;
}
</style>
